<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="user d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0 ms-0">Users</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="redirectToPage">
                                    <i class="fas fa-user-plus me-2"></i>
                                    Add User
                                </material-button>
                            </div>
                        </div>
                    </div>

                    <!-- Card body -->
                    <div class="px-0 pb-0 card-body">
                        <!-- Table -->
                        <table class="table table-flush mt-3">
                            <thead class="thead-light">
                                <tr>
                                    <th>Api Key</th>
                                    <th>Username</th>
                                    <th class="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                  <user-item-list
                                      v-for="item in users"
                                      :key="item.id"
                                      :api_key="item.api_key"
                                      :username="item.username"
                                      :id="item.id"
                                      @delete="handleDelete"
                                  />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/MaterialButton.vue";
  import UserItemList from "./UserItemList.vue";
  import userService from "../../../services/user.service";
  // import historyService from "../../../services/history.service";
  
  export default {
      name: "Pabx",
      components: {
          MaterialButton,
          UserItemList
      },
      mounted() {
        this.loadUsers();
      },
      data() {
          return {
              users: []
          };
      },
      methods: {
        redirectToPage() {
            this.$router.push('/add-user');
        },
        async loadUsers() {
            try {
                this.users = await userService.index();
            } catch (error) {
                console.error('Erro ao obter dados de usuários: ', error);
            }
        },
        async handleDelete(id) {
            try {
                await userService.delete(id);
                this.users = this.users.filter(user => user.id !== id);
            } catch (error) {
                console.error('Erro ao deletar usuário: ', error);
            }
        },
    }
};
</script>

<style scoped>
    table thead tr th {
        padding-left: 1.5rem !important;
    }
</style>
