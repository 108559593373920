<template>
    <tr>
        <td class="text-sm font-weight-normal">
            {{ api_key }}
        </td>

        <td class="text-sm font-weight-normal">
            {{ username }}
        </td>

        <td class="text-sm font-weight-normal">
            <div class="text-end">
                <material-button :disabled="id==1" :id="'delete-' + id" class="m-1 btn-circle" size="sm" color="dark" title="delete" @click="handleDelete">
                    <i class="fas fa-trash"></i>
                </material-button>
            </div>
        </td>
    </tr>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
    name: "UserItemList",
    components: {
        MaterialButton,
    },
    props: {
        api_key: {
            type: String,
            required: true
        },
        username: {
            type: String,
            required: true,
        },
        id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
        };
    },
    methods: {
        handleDelete() {
            this.$emit('delete', this.id);
        },
    }
}
</script>
