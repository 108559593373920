<template>
  <div class="py-4 container-fluid">
      <div class="mt-4 user">
          <div class="col-12">
              <div class="card">
                  <!-- Card header -->
                  <div class="card-header border-bottom">
                      <div class="user d-flex align-items-center">
                          <div class="col-6">
                              <h5 class="mb-0 ms-0">Notifications</h5>
                          </div>
                      </div>
                  </div>

                  <div class="px-0 pb-0 card-body">
                      <table class="table table-flush mt-3">
                          <thead class="thead-light">
                              <tr>
                                  <th>Actions</th>
                                  <th>Created</th>
                                  <th>New Info</th>
                                  <th>Old Info</th>
                              </tr>
                          </thead>
                          <tbody>
                                <notifications-item-list
                                    v-for="(item, index) in notificationsItems"
                                    :key="index"
                                    :actions="item.actions"
                                    :created="item.created"
                                    :new_info="item.new_info"
                                    :old_info="item.old_info"
                                />
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import NotificationsItemList from "./NotificationsItemList.vue";
import historyService from "../../../services/history.service";

export default {
  name: "Notifications",
  components: {
    NotificationsItemList,
  },
  mounted() {
      this.loadNotificationsData();
  },
  data() {
      return {
        notificationsItems: [] 
      };
  },
  methods: {
    async loadNotificationsData() {
      try {
        const notificationsArray = await historyService.getHistory();

        this.notificationsItems = notificationsArray.map(notification => ({
          actions: notification.action.toUpperCase(),
          created: notification.created_at,
          new_info: notification.new_values ?? {},
          old_info: notification.old_values ?? {},
        }));

      } catch (error) {
          console.error('Erro ao obter dados do Notifications:', error);
      }
    },
  }
};
</script>
