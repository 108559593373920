<template>
  <div class="limiter">
    <div class="container-login">
      <div class="wrap-login">
        <div class="login-pic" data-tilt>
          <img src="../assets/img/webrtc_gtw_site.png" alt="IMG" />
        </div>

        <form class="login-form" @submit.prevent="handleLogin">
          <span class="login-form-title">
            User Authentication
          </span>

          <div class="wrap-input">
            <input class="input" type="text" v-model="user.username" name="username" placeholder="Username" />
            <span class="focus-input" :class="{ 'focus-input-error': showError && !user.username }"></span>
            <span class="symbol-input">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </span>
          </div>

          <div class="wrap-input">
            <input class="input" type="password" v-model="user.password" name="password" placeholder="Password" />
            <span class="focus-input"></span>
            <span class="symbol-input">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </span>
          </div>

          <div class="container-login-form-btn">
            <button class="login-form-btn" type="submit">
              log in
            </button>
          </div>

          <div class="text-center p-t-136"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import showSwal from "@/mixins/showSwal";
import { mapMutations } from "vuex";
import * as Yup from 'yup';

export default {
  name: "Login",
  data() {
    return {
      user: { username: "", password: "" },
      showError: false,
      schema: Yup.object().shape({
        username: Yup.string().required("Username is a required input"),
        password: Yup.string().required("Password is a required input")
      }),
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn;
    }
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async handleLogin() {
      try {
        await this.$store.dispatch('auth/login', this.user);
        this.$router.push({ name: 'Pabx' });
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Credenciais inválidas!",
          width: 500
        });
      }
    },
  },
};
</script>
