<template>
    <div class="multisteps-form__panel border-radius-xl bg-white" data-animation="FadeIn">
      <h5 class="font-weight-bolder mb-0">PABX Information</h5>
      <div class="multisteps-form__content">
        <div class="row mt-5">
          <material-input id="api_key" label="API Key" variant="static" v-model:value="api_key" name="api_key" />
        </div>
  
        <div class="row mt-5">
          <material-input id="username" label="Username" variant="static" v-model:value="username" name="username" />
        </div>
  
        <div class="row mt-5">
          <material-input id="password" label="Password" variant="static" v-model:value="password" name="password" />
        </div>
  
        <div class="button-row d-flex mt-4">
          <material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next"
            @click="addUser()">Add</material-button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import MaterialInput from "@/components/MaterialInput.vue";
  import MaterialButton from "@/components/MaterialButton.vue";
  import formMixin from "@/mixins/formMixin.js";
  import showSwal from "@/mixins/showSwal.js";
import userService from "../../../../services/user.service";
  
  export default {
    name: "Info",
    components: {
      MaterialInput,
      MaterialButton,
    },
    data() {
      return {
        api_key: '',
        username: '',
        password: '',
      }
    },
    mixins: [formMixin],
    methods: {
      async addUser() {
        try {
            await userService.create({
                api_key: this.api_key,
                username: this.username,
                passwd: this.password,
            });

            this.redirectToPage();
        } catch(error) {
            console.error("Error creating PABX:", error.response ? error.response.data : error.message);
        }
      },
      redirectToPage() {
        this.showSucessMessage();
        setTimeout(() => {
          this.$router.push('/users');
        }, 3000);
      },
      showSucessMessage() {
        showSwal.methods.showSwal({
          type: "success",
          message: 'User successfully added!!',
          width: 500
        });
      },
    },
  };
  </script>
