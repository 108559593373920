<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 user">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="user d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0 ms-0">Pabx</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm" @click="redirectToPage">
                                    <i class="fas fa-user-plus me-2"></i>
                                    Add Pabx
                                </material-button>
                            </div>
                        </div>
                    </div>
  
                    <!-- Card body -->
                    <div class="px-0 pb-0 card-body">
                        <!-- Table -->
                        <table class="table table-flush mt-3">
                            <thead class="thead-light">
                                <tr>
                                    <th>Label</th>
                                    <th>Domain</th>
                                    <th>IP Address</th>
                                    <th>Port</th>
                                    <th>Transport Protocol</th>
                                    <th class="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                  <pabx-item-list
                                      v-for="item in pabxItems"
                                      :key="item.id"
                                      :name="item.name"
                                      :dns="item.dns"
                                      :host="item.host"
                                      :port="item.port"
                                      :transport="item.transport"
                                      :id="item.id"
                                      @delete="handleDelete"
                                      @edit="handleEdit"
                                  />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import MaterialButton from "@/components/MaterialButton.vue";
  import PabxItemList from "./PabxItemList.vue";
  import pabxService from "../../../services/pabx.service";
  import historyService from "../../../services/history.service";
  
  export default {
      name: "Pabx",
      components: {
          MaterialButton,
          PabxItemList,
      },
      mounted() {
          this.loadPabxData();
      },
      data() {
          return {
              pabxItems: []
          };
      },
      methods: {
        redirectToPage() {
            this.$router.push('/add-pabx');
        },
        async loadPabxData() {
            try {
                const pabxArray = await pabxService.getAllPabx();

                this.pabxItems = pabxArray.map(pabx => ({
                    name: pabx.name,
                    host: pabx.host,
                    dns: pabx.fqdn,
                    port: pabx.port,
                    transport: pabx.transport.toUpperCase(),
                    id: pabx.id,
                }));

            } catch (error) {
                console.error('Erro ao obter dados do PABX:', error);
            }
        },
        async handleDelete(id) {
            try {
                const oldPabx = this.pabxItems.find(item => item.id === id);
                const oldPabxValues = oldPabx ? {
                    host: oldPabx.host,
                    name: oldPabx.name,
                    port: oldPabx.port,
                    dns: oldPabx.dns,
                    transport: oldPabx.transport
                } : null; 

                const response =  await pabxService.deletePabx(id);

                this.pabxItems = this.pabxItems.filter(item => item.id !== id);

                if(response === id) {
                    historyService.createHistory(
                        'Delete',
                        oldPabxValues
                    )
                }
            } catch (error) {
                console.error('Erro ao deletar o PABX:', error);
            }
        },
        async handleEdit({ id, name, host, port, dns, transport }) {
            const oldPabx = this.pabxItems.find(item => item.id === id);
            const oldPabxValues = oldPabx ? {
                host: oldPabx.host,
                name: oldPabx.name,
                port: oldPabx.port,
                dns: oldPabx.dns,
                transport: oldPabx.transport
            } : null;

            try {
                const response = await pabxService.updatePabx(id, { host, name, port: port, transport });

                this.pabxItems = this.pabxItems.map(item =>
                    item.id === id ? { ...item, host, name, port: port, dns, transport } : item
                );
                if(response === id) {
                    historyService.createHistory(
                        'Update',
                        oldPabxValues,
                        { host, name, port, dns, transport }
                    )
                }

            } catch (error) {
                console.error('Erro ao atualizar o PABX:', error);
            }
        },
    }
};
</script>

<style scoped>
    table thead tr th {
        padding-left: 1.5rem !important;
    }
</style>
