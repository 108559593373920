import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./assets/css/styles.css";
import "./assets/scss/styles.scss";
import "./assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);

appInstance.mount("#app");
