<template>
    <tr>
        <td class="text-sm font-weight-normal">
            {{ actions }}
        </td>

        <td class="text-sm font-weight-normal">
            {{ created }}
        </td>

        <td class="text-sm font-weight-normal"> 
            <pre>{{ formatJson(new_info) }}</pre>
        </td>

        <td class="text-sm font-weight-normal">
            <pre>{{ formatJson(old_info) }}</pre>
        </td>
    </tr>
</template>

<script>

export default {
    name: "NotificationsItemList",
    props: {
        actions: {
            type: String,
            required: true
        },
        created: {
            type: String,
            required: true,
        },
        new_info: {
            type: Object,
            required: true
        },
        old_info: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatJson(json) {
            try {
                return JSON.stringify(JSON.parse(json), null, 2);
            } catch (e) {
                return json;
            }
        }
    }
}
</script>