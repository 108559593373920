import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}/admin/history/`

export default {
    async createHistory(action, old_values = null, new_values = null) {
        try {
            const data = {
                action: action,
                old_values: old_values, 
                new_values: new_values  
            };
                
            const response = await axios.post(API_URL, data, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader(),
                    'Content-Type': 'application/json'
                }
            });
            return response;
        } catch (error) {
            console.error("Request error: ", error.response ? error.response.data : error.message);
            throw error;
        }
    },    
    async getHistory() {
        try {
            const response = await axios.get(API_URL, { 
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader()
                }
            });
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    }
}
