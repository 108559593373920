import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}`
const BASE_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}`

export default {

  async login(user) {
    try {
      var response = await axios.post(API_URL + '/login', user, 
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
      if (response.status === 200) {
        localStorage.setItem('bearer', JSON.stringify(response.data.token));
        localStorage.setItem('id', JSON.stringify(response.data.id));
      }
      return response.data;
    } catch (error) {
      console.error("Erro na requisição: ", error);
      throw error;
    }
  },

  async logout() {
    await axios.post(API_URL + "/logout", {}, { headers: authHeader() })
    localStorage.removeItem('bearer');
  },

  async passwordForgot(userEmail) {

    var response = await axios.post(API_URL + '/password-forgot', {
      redirect_url: BASE_URL + "/password-reset",
      email: userEmail
    })
    return response.status;
  },

  async passwordReset(passwordDTO) {
    const { password, password_confirmation } = passwordDTO;
    if( password !== password_confirmation )
    {
      throw new Error("Passwords do not match");
    }

    var response = await axios.put(API_URL + '/admin/user/password', {
      password,
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': authHeader(),
      }
    });
    if (response.status !== 200) {
      throw new Error("Error in request");
    }
  }
}
