import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}`

export default {
    async index() {
        try {
            const response = await axios.get(API_URL + '/admin/user',{ 
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader()
                }
            });
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    },

    async create(user) {
        try {
            const response = await axios.post(API_URL + '/admin/user', JSON.stringify(user), { 
                headers: {
                    "Content-Type": 'application/json',
                    'Authorization': authHeader()
                },
            });
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    },

    async delete(id) {
        try {
            await axios.delete(API_URL + '/admin/user/' + id, { 
                headers: {
                    'Authorization': authHeader()
                }
            });
        } catch (error) {
            console.error("Request error: ", error);
        }
    }
}
