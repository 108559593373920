import axios from 'axios';
import { authHeader } from './auth-header';

const API_URL = `${process.env.VUE_APP_BASE_URL}:${process.env.VUE_APP_PORT}`

export default {
    async createPabx(name, host, port, dns = '', transport ) {
        try {
            const data = {
                name: name,
                host: host,      
                port: port,      
                fqdn: dns,
                transport: transport
            };
            
            const response = await axios.post(`${API_URL}/admin/pabx/`, data, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader(),
                    'Content-Type': 'application/json'
                }
            });
            return response;
        } catch (error) {
            console.error("Request error: ", error.response ? error.response.data : error.message);  
            throw error; 
        }
    },    
    async updatePabx(id, { name, host, port, dns, transport }) {
        try {
            const formatedtransport = transport.charAt(0).toUpperCase() + transport.slice(1).toLowerCase();
            const data = {
                ...(name !== undefined && { name }),
                ...(host !== undefined && { host }),
                ...(port !== undefined && { port: Number(port) }),
                ...(dns !== undefined && { dns }),
                ...(transport !== undefined && { transport: formatedtransport })
            };
    
            const response = await axios.put(`${API_URL}/admin/pabx/${id}`, data, {
                headers: {
                    Accept: 'text/plain',
                    'Authorization': authHeader(),
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                console.error("Erro na resposta da API: ", error.response.data);
            } else if (error.request) {
                console.error("Nenhuma resposta recebida: ", error.request);
            } else {
                console.error("Erro na configuração da requisição: ", error.message);
            }
        }
    },
    async deletePabx(id) {
        try {
            const response = await axios.delete(`${API_URL}/admin/pabx/${id}`, { 
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader()
                }
            });
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    },
    async getAllPabx() {
        try {
            const response = await axios.get(API_URL + '/pabx',{ 
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader()
                }
            });
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    },
    async getPabxName(name) {
        try {
            var response = await axios.get(API_URL + `/pabx/${name}`, { 
                headers: {
                    Accept: 'application/json',
                    'Authorization': authHeader()
                }
            });
   
            return response.data;
        } catch (error) {
            console.error("Request error: ", error);
        }
    },
}
