import { createRouter, createWebHistory } from "vue-router";
import Notifications from "../views/components/notifications/Notifications.vue";
import Login from "../views/Login.vue";
import Pabx from "../views/components/pabx/Pabx.vue";
import Users from "../views/components/users/Users.vue";
import AddPabx from "../views/components/pabx/addPabx/AddPabx.vue";
import AddUser from "../views/components/users/addUser/AddUser.vue";
import User from "../views/components/profile/User.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/user",
    name: "Account Settings",
    component: User
  },
  {
    path: "/pabx",
    name: "Pabx",
    component: Pabx,
  },
  {
    path: "/add-pabx",
    name: "Add PABX",
    component: AddPabx,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/add-user",
    name: "Add User",
    component: AddUser,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
