<template>
  <div class="multisteps-form__panel border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">PABX Information</h5>
    <div class="multisteps-form__content">
      <div class="row mt-5">
        <material-input id="name" label="Label" variant="static" v-model:value="name" name="name" />
      </div>

      <div class="row mt-5">
        <material-input id="host" label="IP Address" variant="static" v-model:value="host" name="host"
          :is-valid="isValidHost" :error-message="hostErrorMessage" />
      </div>

      <div class="row mt-5">
        <material-input id="dns" label="Domain" variant="static" v-model:value="dns" name="dns" />
      </div>

      <div class="row mt-5">
        <material-input id="port" label="Port" variant="static" v-model:value="port" name="port" type="text" @input="validatePort"/>
      </div>

      <div class="row mt-5">
        <label for="transport">Transport Protocol </label>
        <div id="transport" class="d-flex">
          <div v-for="option in transportOptions" :key="option.value" class="form-check me-3">
            <input type="radio" :id="option.value" :value="option.value" v-model="transport" :name="name"
              class="custom-radio" />
            <label :for="option.value" class="form-check-label">
              {{ option.text }}
            </label>
          </div>
        </div>
      </div>

      <div class="button-row d-flex mt-4">
        <material-button type="button" color="dark" variant="gradient" class="ms-auto mb-0 js-btn-next"
          @click="addPabx()">Add</material-button>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "@/mixins/showSwal.js";
import pabxService from "../../../../services/pabx.service";
import historyService from "../../../../services/history.service";

export default {
  name: "Info",
  components: {
    MaterialInput,
    MaterialButton,
  },
  data() {
    return {
      name: '',
      host: '',
      dns: '',
      port: '',
      transport: 'Udp',
      apiValidationErrors: {
        transport: []
      },
      transportOptions: [
        { value: 'Udp', text: 'UDP' },
        { value: 'Tcp', text: 'TCP' },
        { value: 'Tls', text: 'TLS' },
      ],
      hostErrorMessage: '',
      isValidHost: true,
    }
  },
  mixins: [formMixin],
  methods: {
    async addPabx() {
      const transport = this.formatTransport(this.transport);
      const port = parseInt(this.port, 10);

      if (!this.isValidIp(this.host)) {
        this.showErrorIP();
        return;
      }

      try {
        const response = await pabxService.createPabx(this.name, this.host, port, this.dns, transport);

        if (response.status === 201) {
          historyService.createHistory(
            'Create',
            null,
            { name: this.name, host: this.host, port, dns: this.dns, transport }
          )
          this.redirectToPage();
        }
      } catch (error) {
        console.error("Error creating PABX:", error.response ? error.response.data : error.message);
      }
    },
    formatTransport(transport) {
      const formattedTransport = transport.charAt(0).toUpperCase() + transport.slice(1).toLowerCase();

      const allowedTypes = ["Tcp", "Udp", "Tls"];
      if (!allowedTypes.includes(formattedTransport)) {
        this.showErrorMessageTransport();
      }

      return formattedTransport;
    },
    redirectToPage() {
      this.showSucessMessage();
      setTimeout(() => {
        this.$router.push('/pabx');
      }, 3000);
    },
    showSucessMessage() {
      showSwal.methods.showSwal({
        type: "success",
        message: 'PABX successfully added!!',
        width: 500
      });
    },
    showErrorMessageTransport() {
      showSwal.methods.showSwal({
        type: "error",
        message: 'Please add a valid transport!!',
        width: 500
      });
    },
    validatePort(event) {
      this.port = event.target.value < 0 ? '' : String(event.target.value);
    },
    isValidIp(ip) {
      const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
      return ipRegex.test(ip);
    },
    showErrorIP() {
      showSwal.methods.showSwal({
        type: "error",
        message: 'The entered IP is not valid!!',
        width: 500
      });
    },
  },
};
</script>

<style scoped>
.custom-radio:checked {
  accent-color: #006a61;
}
</style>