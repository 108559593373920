<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">

      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="pabx"
          navText="Pabx">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">home_max</i>
          </template>
        </sidenav-collapse>
      </li>

      <template v-if="getId() == 1">
        <li class="nav-item">
          <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="users"
            navText="Users">
            <template v-slot:icon>
              <i class="material-icons-round opacity-10 fs-5">person</i>
            </template>
          </sidenav-collapse>
        </li>
      </template>

      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="notifications"
          navText="Logs">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">description</i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse url="#" :aria-controls="''" v-bind:collapse="false" collapseRef="user"
          navText="Account Settings">
          <template v-slot:icon>
            <i class="material-icons-round opacity-10 fs-5">person</i>
          </template>
        </sidenav-collapse>
      </li>

    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import { getId } from "../../../services/auth-header";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavCollapse
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    getId() {
      return getId();
    }
  }
};
</script>
