export function authHeader() {
  let bearer = JSON.parse(localStorage.getItem('bearer'));
  if (bearer) {
    return bearer;
  } 
}

export function getId() {
  let id = JSON.parse(localStorage.getItem('id'));
  if (id) {
    return id;
  } 
}