<template>
    <tr>
        <td class="text-sm font-weight-normal">
            <template v-if="isEditing">
                <input v-model="editName" type="text" class="form-control" />
            </template>
            <template v-else>
                {{ name }}
            </template>
        </td>

        <td class="text-sm font-weight-normal">
            <template v-if="isEditing">
                <input v-model="editDns" type="text" class="form-control" />
            </template>
            <template v-else>
                {{ dns }}
            </template>
        </td>
                
        <td class="text-sm font-weight-normal">
            <template v-if="isEditing">
                <input v-model="editHost" type="text" class="form-control" />
                <span v-if="!isValidIp(editHost)" class="text-danger">IP inválido</span>
            </template>
            <template v-else>
                {{ host }}
            </template>
        </td>

        <td class="text-sm font-weight-normal">
            <template v-if="isEditing">
                <input v-model.number="editPort" type="number" class="form-control" min="0" />
            </template>
            <template v-else>
                {{ port }}
            </template>
        </td>

        <td class="text-sm font-weight-normal">
            <template v-if="isEditing">
                <div class="radio-group">
                    <input class="custom-radio" type="radio" id="udp" value="UDP" v-model="editTransport" />
                    <label for="udp">UDP</label>
                    
                    <input class="custom-radio" type="radio" id="tcp" value="TCP" v-model="editTransport" />
                    <label for="tcp">TCP</label>
                    
                    <input class="custom-radio" type="radio" id="tls" value="TLS" v-model="editTransport" />
                    <label for="tls">TLS</label>
                </div>
            </template>
            <template v-else>
                {{ transport }}
            </template>
        </td>

        <td class="text-sm font-weight-normal">
            <div class="text-end">
                <template v-if="isEditing">
                    <material-button class="m-1 btn-circle" title="save" size="sm" @click="saveEdit">
                        <i class="fas fa-save"></i>
                    </material-button>
                    <material-button class="m-1 btn-circle" title="cancel" color="dark" size="sm" @click="cancelEdit">
                        <i class="fas fa-close"></i>
                    </material-button>
                </template>
                <template v-else>
                    <material-button :id="'edit-' + id" class="m-1 btn-circle" title="edit" size="sm" @click="startEdit">
                        <i class="fas fa-pen"></i>
                    </material-button>
                    <material-button :id="'delete-' + id" class="m-1 btn-circle" size="sm" color="dark" title="delete" @click="handleDelete">
                        <i class="fas fa-trash"></i>
                    </material-button>
                </template>
            </div>
        </td>
    </tr>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";
import showSwal from "@/mixins/showSwal.js";


export default {
    name: "PabxItemList",
    components: {
        MaterialButton,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        host: {
            type: String,
            required: true,
            default: '0.0.0.0'
        },
        port: {
            type: Number,
            required: true
        },
        transport: {
            type: String,
            required: true
        },
        dns: {
            type: String,
            required: false, 
            default: ''
        },
        id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isEditing: false,
            editName: this.name,
            editHost: this.host,
            editPort: this.port,
            editTransport: this.transport,
            editDns: this.dns || ''
        };
    },
    methods: {
        startEdit() {
            this.isEditing = true;
        },
        cancelEdit() {
            this.isEditing = false;
            this.editName = this.name;
            this.editHost = this.host;
            this.editPort = this.port;
            this.editTransport = this.transport;
        },
        saveEdit() {
            this.editPort = isNaN(this.editPort) || this.editPort < 0 ? 0 : this.editPort;

            if (!this.isValidIp(this.editHost)) {
                this.showErrorIP();
                return;
            }

            this.$emit('edit', {
                id: this.id,
                name: this.editName,
                dns: this.editDns,
                host: this.editHost,
                port: this.editPort,
                transport: this.editTransport
            });
            this.isEditing = false;
        },
        handleDelete() {
            this.$emit('delete', this.id);
        },
        isValidIp(ip) {
            const ipRegex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
            return ipRegex.test(ip);
        },
        showErrorIP() {
            showSwal.methods.showSwal({
                type: "error",
                message: 'The entered IP is not valid!!',
                width: 500
            });
        },
    }
}
</script>

<style scoped>
    .btn-circle.btn-sm {
        width: 30px;
        height: 30px;
        padding: 6px 0px;
        border-radius: 15px;
        font-size: 8px;
        text-align: center;
    }

    table tbody tr td {
        padding-left: 1.5rem !important;
    }

    .custom-radio:checked {
        accent-color: #006a61;
    }

    .radio-group input[type="radio"] {
        margin-right: 8px; 
    }

    .radio-group label {
        margin-right: 16px;
    }

    .text-danger {
        color: #dc3545;
        font-size: 0.875rem;
    }
</style>
